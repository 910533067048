const config = {
  urls: {
    apiProxyUrl: `${process.env.REACT_APP_API_PROXY_URL}`,
    loginUrl: `${process.env.REACT_APP_ACCOUNTS_URL}/login`,
    replicatorApiUrl: `${process.env.REACT_APP_REPLICATOR_API_URL}`,
  },
  region: `${process.env.REACT_APP_REGION}`,
  rootHost: `${process.env.REACT_APP_ROOT_HOST}`,
};

export default config;
