import WebSocketServiceListener from "./WebSocketServiceListener";

export enum WebSocketServiceState {
  Open,
  Closed,
}

export default class WebSocketService {
  private socket: WebSocket;
  private state: WebSocketServiceState;
  private listener?: WebSocketServiceListener;

  constructor(url: string) {
    this.state = WebSocketServiceState.Closed;
    this.socket = new WebSocket(url);
    this.socket.onopen = this.onOpen;
    this.socket.onerror = this.onError;
    this.socket.onmessage = this.onMessage;
    this.socket.onclose = this.onClose;
  }

  getState(): WebSocketServiceState {
    return this.state;
  }

  setListener(listener: WebSocketServiceListener) {
    this.listener = listener;
  }

  private onOpen = () => {
    this.state = WebSocketServiceState.Open;
    this.listener?.onOpenConnection(this);
  };

  private onClose = async (e: CloseEvent) => {
    this.state = WebSocketServiceState.Closed;
    this.listener?.onCloseConnection(this, e);
  };

  private onMessage = (event: MessageEvent) => {
    const msg = JSON.parse(event.data);
    this.listener?.onMessageReceived(this, msg);
  };

  private onError = (e) => {
    this.listener?.onError(this, e);
  };

  protected send = (message) => {
    console.log(`Send message - ${JSON.stringify(message)}`);
    this.socket.send(JSON.stringify(message));
  };
}
