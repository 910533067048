import React from "react";
import config from "../config";
import ReplicatorApiService from "services/ReplicatorApiService";

export const ApiContext = React.createContext<ReplicatorApiService | undefined>(undefined);

export function useApi() {
  const context = React.useContext(ApiContext);
  if (context === undefined) {
    throw new Error("useApi must be used within an ReplicatorApiService");
  }
  return context;
}

export default function ReplicatorApiProvider({ children }: { children: React.ReactNode }) {
  return (
    <ApiContext.Provider value={new ReplicatorApiService(config.urls.replicatorApiUrl)}>{children}</ApiContext.Provider>
  );
}
