import React from "react";
import GlobalNavigation, { Logo, CurrentApp, User } from "@acl-services/sriracha-global-navigation";
import Replicator from "./components/Replicator";
import "@acl-services/sriracha-global-navigation/dist/GlobalNavigation.scss";
import "./App.scss";

import ReplicatorApiProvider from "providers/ReplicatorApiProvider";
import { Provider } from "react-redux";
import { initializeStore } from "store/store";

export default function App() {
  return (
    <Provider store={initializeStore()}>
      <div className="layout">
        <ReplicatorApiProvider>
          <div className="sriracha">
            <GlobalNavigation>
              <Logo launchpadUrl={"https://www.wegalvanize.com"} />
              <CurrentApp appModuleName="Replicator" appUrl="/" />
              <User
                username={"Username"}
                userProfileUrl={"https://www.wegalvanize.com"}
                logoutUrl={"https://www.wegalvanize.com"}
              />
            </GlobalNavigation>
          </div>
          <Replicator />
        </ReplicatorApiProvider>
      </div>
    </Provider>
  );
}
