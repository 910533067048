import produce from "immer";
import { Replica } from "services/ReplicatorApiService";
import { CONTAINER_STATE_UPDATED, REPLICA, SWITCH_REPLICA, UPDATE_REPLICA } from "./actions";

const initialState = {
  loading: false,
  current: null as Replica | null,
  timer: null as NodeJS.Timeout | null,
};

const createEmptyReplica = (name: string): Replica => {
  return {
    name: name,
    token: "",
    script: "",
    sourceRegion: "",
    sourceOrgId: "",
    targetOrgId: "",
    logs: [],
    running: false,
  };
};

export const replicaReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONTAINER_STATE_UPDATED:
      if (action.containerState === "stopped") {
        return produce(state, (draftState) => {
          draftState.current = null;
        });
      } else {
        return state;
      }
    case REPLICA:
      if (state.current?.name === action.replica.name) {
        return produce(state, (draftState) => {
          draftState.loading = false;
          draftState.current = action.replica;
        });
      } else {
        return state;
      }
    case SWITCH_REPLICA:
      return produce(state, (draftState) => {
        draftState.loading = action.loading;
        draftState.current = createEmptyReplica(action.targetReplicaName);
        draftState.timer = null;
      });
    case UPDATE_REPLICA:
      return produce(state, (draftState) => {
        draftState.current = action.updatedReplica;
        draftState.timer = action.timer;
      });
    default:
      return state;
  }
};
