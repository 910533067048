import WebSocketService from "./WebSocketService";

export interface Replica {
  name: string;
  token: string;
  script: string;
  logs: string[];
  running: boolean;
  sourceRegion: string;
  sourceOrgId: string;
  targetOrgId: string;
}
export default class ReplicatorApiService extends WebSocketService {
  getContainerState() {
    console.log("getContainerState");
    const msg = {
      action: "container_state",
      message: "",
    };
    this.send(msg);
  }

  startContainer() {
    console.log("startContainer");
    const msg = {
      action: "container_start",
      message: "",
    };
    this.send(msg);
  }

  getReplicas() {
    console.log("getReplicas");
    const msg = {
      action: "command",
      type: "getReplicas",
    };
    this.send(msg);
  }

  addReplica(name) {
    console.log("addReplica");
    const msg = {
      action: "command",
      type: "addReplica",
      name: name,
    };
    this.send(msg);
  }

  /* getReplica(name) {
    console.log("getReplica");
    const msg = {
      action: "command",
      type: "getReplica",
      name: name,
    };
    this.send(msg);
  } */

  saveReplica(replica: Replica) {
    console.log("saveReplica");
    const msg = {
      ...replica,
      action: "command",
      type: "saveReplica",
    };
    this.send(msg);
  }

  getReplica(name) {
    console.log("getReplica");
    const msg = {
      action: "command",
      type: "getReplica",
      name: name,
    };
    this.send(msg);
  }

  execute(replica: Replica) {
    console.log("execute");
    const msg = {
      ...replica,
      action: "command",
      type: "execute",
    };
    this.send(msg);
  }

  stopContainer() {
    console.log("stopContainer");
    const msg = {
      action: "container_stop",
      message: "",
    };
    this.send(msg);
  }
}
