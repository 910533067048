import { combineReducers, configureStore, getDefaultMiddleware, Store } from "@reduxjs/toolkit";
import { containerReducer } from "./containerReducer";
import { replicaReducer } from "./replicaReducer";

// REDUCERS
export const reducer = combineReducers({
  container: containerReducer,
  replica: replicaReducer,
});

export function initializeStore(preloadedState = {}): Store {
  return configureStore({
    reducer,
    middleware: getDefaultMiddleware(),
    preloadedState,
  });
}
