import produce from "immer";
import { CONTAINER_STATE_UPDATED, GET_REPLICAS, REPLICAS, SWITCH_REPLICA } from "./actions";

enum ContainerState {
  Loading = 1,
  Starting,
  Stopping,
  Started,
  Stopped,
}

const initialState = {
  state: ContainerState.Loading,
  replicasLoading: false,
  replicas: [] as string[],
};

export const containerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONTAINER_STATE_UPDATED:
      return produce(state, (draftState) => {
        switch (action.containerState) {
          case "stopped":
            draftState.state = ContainerState.Stopped;
            draftState.replicas = [];
            break;
          case "started":
            draftState.state = ContainerState.Started;
            break;
          case "stopping":
            draftState.state = ContainerState.Stopping;
            break;
          case "starting":
            draftState.state = ContainerState.Starting;
            break;
        }
      });
    case GET_REPLICAS:
      return produce(state, (draftState) => {
        draftState.replicasLoading = true;
        draftState.replicas = [];
      });
    case REPLICAS:
      return produce(state, (draftState) => {
        draftState.replicasLoading = false;
        draftState.replicas = action.replicas;
      });
    case SWITCH_REPLICA:
      return produce(state, (draftState) => {
        if (!state.replicas.includes(action.targetReplicaName)) {
          draftState.replicas.push(action.targetReplicaName);
        }
      });
    default:
      return state;
  }
};
