import React, { useState } from "react";
import Input from "@paprika/input";
import Textarea from "@paprika/textarea";
import Button from "@paprika/button";
import ListBox from "@paprika/list-box";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-typescript";
import "ace-builds/src-noconflict/theme-eclipse";

import { useApi } from "../providers/ReplicatorApiProvider";
import "./Replicator.scss";
import WebSocketServiceListener from "services/WebSocketServiceListener";
import WebSocketService from "services/WebSocketService";
import config from "config";
import { useDispatch, useSelector } from "react-redux";
import {
  createReplica,
  executeCurrentReplica,
  messageReceived,
  startContainer,
  stopContainer,
  switchReplica,
  updateCurrentReplica,
} from "store/actions";

type Props = {};

enum State {
  Loading = 1,
  Starting,
  Stopping,
  Started,
  Stopped,
}

export default function Replicator(props: Props) {
  const api = useApi();
  const [newReplicaName, setNewReplicaName] = useState<string>("");
  const [sourceRegions] = useState<string[]>(["af", "ap", "au", "ca", "eu", "sa", "us"]);

  const dispatch = useDispatch();

  const container = useSelector((state) => (state as any).container);
  const replicaLoading = useSelector((state) => (state as any).replica.loading);
  const selectedReplica = useSelector((state) => (state as any).replica.current);
  const selectedReplicaLogs = useSelector((state) => (state as any).replica.current?.logs?.join("\n") || "");

  const getContainerState = () => {
    api.getContainerState();
  };
  const webSocketListener = new (class implements WebSocketServiceListener {
    onOpenConnection(service: WebSocketService) {
      console.log("Open");
      getContainerState();
      // setState(State.Ready);
    }

    onCloseConnection(service: WebSocketService, event: CloseEvent) {
      console.log("Close");
      if (event.code === 1006 && event.reason === "") {
        global.location.href = config.urls.loginUrl;
      }
    }

    onMessageReceived(service: WebSocketService, message) {
      dispatch(messageReceived(api, message));
    }

    onError(service: WebSocketService, event) {
      console.log("Error");
    }
  })();
  api.setListener(webSocketListener);

  const onChangeReplicaName = (event) => {
    const text = event.target.value;
    setNewReplicaName(text);
  };

  const onSelectReplicaItem = (index) => {
    if (container.replicas[index]) {
      dispatch(switchReplica(api, container.replicas[index]));
    }
  };

  const onSelectSourceRegionItem = (index) => {
    if (sourceRegions[index]) {
      dispatch(updateCurrentReplica(api, { sourceRegion: sourceRegions[index] }));
    }
  };

  const onChangeToken = (event) => {
    dispatch(updateCurrentReplica(api, { token: event.target.value }));
  };

  const onChangeScript = (text) => {
    dispatch(updateCurrentReplica(api, { script: text }));
  };

  const onStartContainer = () => {
    dispatch(startContainer(api));
  };

  const onStopContainer = () => {
    dispatch(stopContainer(api));
  };

  const onStartExecution = () => {
    dispatch(executeCurrentReplica(api));
  };

  const onCreateReplica = () => {
    dispatch(createReplica(api, newReplicaName));
    setNewReplicaName("");
  };

  const updateReplicaListItems = (): JSX.Element[] => {
    return container.replicas.length === 0
      ? [<ListBox.Option key="empty">&nbsp;</ListBox.Option>]
      : container.replicas.map((replica) => <ListBox.Option key={replica}>{replica}</ListBox.Option>);
  };

  const updateSourceRegions = (): JSX.Element[] => {
    return sourceRegions.length === 0
      ? [<ListBox.Option key="empty">&nbsp;</ListBox.Option>]
      : sourceRegions.map((reg) => (
          <ListBox.Option key={reg} isSelected={reg === selectedReplica?.sourceRegion}>
            {reg}
          </ListBox.Option>
        ));
  };

  const onChangeSourceOrgId = (event) => {
    dispatch(updateCurrentReplica(api, { sourceOrgId: event.target.value }));
  };

  const onChangeTargetOrgId = (event) => {
    dispatch(updateCurrentReplica(api, { targetOrgId: event.target.value }));
  };

  const showLoadingMessage = () => {
    switch (container.state) {
      case State.Starting:
        return "Starting... (this may take a few minutes)";
      case State.Stopping:
        return "Stopping... (this may take a few minutes)";
      default:
        return "Loading...";
    }
  };

  return (
    <div className="replicator">
      {container.state === State.Loading ||
      container.state === State.Starting ||
      container.state === State.Stopping ||
      replicaLoading ? (
        <div className="loading_container">{showLoadingMessage()}</div>
      ) : null}
      <div className="sessions_container">
        <div className="create_container">
          <Input
            onChange={onChangeReplicaName}
            isDisabled={container.state !== State.Started}
            placeholder="Enter replica name"
            size="medium"
            value={newReplicaName}
          />
          &nbsp;&nbsp;
          <Button
            kind="secondary"
            isDisabled={
              container.state !== State.Started || !newReplicaName.length || container.replicas.includes(newReplicaName)
            }
            onClick={onCreateReplica}
          >
            Create
          </Button>
        </div>
        <div className="list_container">
          <ListBox
            isDisabled={container.state !== State.Started}
            onChange={onSelectReplicaItem}
            isInline={true}
            placeholder={container.replicasLoading ? "Loading..." : "Select replica..."}
          >
            {updateReplicaListItems()}
          </ListBox>
        </div>
        <div className="action_container">
          <Button kind="primary" onClick={onStartContainer} isDisabled={container.state !== State.Stopped}>
            Start
          </Button>
          &nbsp;&nbsp;
          <Button kind="destructive" onClick={onStopContainer} isDisabled={container.state !== State.Started}>
            Stop
          </Button>
        </div>
      </div>
      {selectedReplica ? (
        <div className="data_container">
          <div className="name_container">
            <b>{selectedReplica.name}</b>
          </div>
          <div className="run_container">
            <Button kind="primary" onClick={onStartExecution} isDisabled={selectedReplica.running}>
              Run
            </Button>
          </div>
          <div className="settings_container">
            <div>
              Source:&nbsp;
              <ListBox
                isDisabled={selectedReplica.running}
                onChange={onSelectSourceRegionItem}
                placeholder="Select source region..."
              >
                {updateSourceRegions()}
              </ListBox>
              &nbsp;&nbsp;&nbsp;
              <Input
                onChange={onChangeSourceOrgId}
                isDisabled={selectedReplica.running}
                placeholder="Source Organization Id"
                size="medium"
                value={selectedReplica.sourceOrgId}
              />
            </div>
            <div>
              Target:&nbsp;&nbsp;
              <ListBox isDisabled={true}>
                {[
                  <ListBox.Option key="target" isSelected={true}>
                    {config.region}
                  </ListBox.Option>,
                ]}
              </ListBox>
              &nbsp;&nbsp;&nbsp;
              <Input
                onChange={onChangeTargetOrgId}
                isDisabled={selectedReplica.running}
                placeholder="Target Organization Id"
                size="medium"
                value={selectedReplica.targetOrgId}
              />
            </div>
          </div>
          <div className="input_container">
            <Input onChange={onChangeToken} placeholder="Your token" size="medium" value={selectedReplica.token} />
          </div>
          <div className="script_container">
            <AceEditor
              placeholder="Write your script here..."
              mode="typescript"
              theme="eclipse"
              name="script-editor"
              onChange={onChangeScript}
              fontSize={14}
              showPrintMargin={false}
              showGutter={true}
              highlightActiveLine={true}
              height="40vh"
              width="100%"
              value={selectedReplica.script}
              setOptions={{
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: true,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
              }}
            />
          </div>
          <div className="log_container">
            <Textarea
              className="log"
              isReadOnly={true}
              placeholder=""
              size="medium"
              defaultValue={selectedReplicaLogs}
              maxHeight="250px"
            />
          </div>
        </div>
      ) : (
        <div className="data_container">
          {container.state === State.Stopped ? (
            <p>Press "Start" button at the bottom left to start your Replicator worker</p>
          ) : null}
          {container.state === State.Started && !selectedReplica ? (
            <p>
              Please create your first replica by typing the name in the text input on the left and pressing "Create".
              <br />
              Then select your replica from the list to get started with the migration.
            </p>
          ) : null}
        </div>
      )}
    </div>
  );
}
