import { Replica } from "services/ReplicatorApiService";

export const CONTAINER_STATE_UPDATED = "CONTAINER_STATE_UPDATED";
export const GET_REPLICAS = "GET_REPLICAS";
export const REPLICAS = "REPLICAS";
export const REPLICA = "REPLICA";
export const SWITCH_REPLICA = "SWITCH_REPLICA";
export const UPDATE_REPLICA = "UPDATE_REPLICA";

export const startContainer = (api) => async (dispatch) => {
  dispatch({ type: CONTAINER_STATE_UPDATED, containerState: "starting" });
  api.startContainer();
};

export const stopContainer = (api) => async (dispatch) => {
  dispatch({ type: CONTAINER_STATE_UPDATED, containerState: "stopping" });
  api.stopContainer();
};

export const getReplicas = (api) => async (dispatch) => {
  dispatch({ type: GET_REPLICAS });
  api.getReplicas();
};

export const switchReplica = (api, targetReplicaName) => async (dispatch) => {
  dispatch({ type: SWITCH_REPLICA, targetReplicaName, loading: true });
  api.getReplica(targetReplicaName);
};

export const createReplica = (api, newReplicaName) => async (dispatch) => {
  dispatch({ type: SWITCH_REPLICA, targetReplicaName: newReplicaName, loading: false });
  api.addReplica(newReplicaName);
};

const cancelSaveTimer = (timer) => {
  if (timer) {
    clearTimeout(timer);
  }
};

export const updateCurrentReplica = (api, updates) => async (dispatch, getState) => {
  const updatedReplica = { ...getState().replica.current, ...updates };
  cancelSaveTimer(getState().replica.timer);
  const timer = setTimeout(() => {
    api.saveReplica(updatedReplica);
  }, 1000);
  dispatch({ type: UPDATE_REPLICA, updatedReplica, timer });
};

export const executeCurrentReplica = (api) => async (dispatch, getState) => {
  const updatedReplica = { ...getState().replica.current, running: true, logs: [] };
  dispatch({ type: UPDATE_REPLICA, updatedReplica, timer: getState().replica.timer });
  api.execute(updatedReplica);
};

export const messageReceived = (api, message) => async (dispatch, getState) => {
  console.log(`Got message ${JSON.stringify(message)}`);
  if (message["action"] === "container_state") {
    console.log(`Message - ${message.state}`);
    dispatch({ type: CONTAINER_STATE_UPDATED, containerState: message.state });
    if (message.state === "started") {
      dispatch(getReplicas(api));
    }
  } else if (message["action"] === "replicas") {
    console.log(`Replicas - ${message.state}`);
    dispatch({ type: REPLICAS, replicas: JSON.parse(message.state) });
  } else if (message["action"] === "replica") {
    const replica = JSON.parse(message.state) as Replica;
    if (getState().replica.current?.name === replica.name) {
      dispatch({ type: REPLICA, replica: replica });
      if (replica.running) {
        setTimeout(() => {
          api.getReplica(replica.name);
        }, 3000);
      }
    }
  }
};
